// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */

import * as React from "react";
import { createUseScreenVariants } from "@plasmicapp/react-web";

export type MobileValue =
  | "mobile"
  | "tabletPortrait"
  | "tabletLandscape"
  | "mobileXSmall";
export const MobileContext = React.createContext<MobileValue[] | undefined>(
  "PLEASE_RENDER_INSIDE_PROVIDER" as any
);

export const useScreenVariants = createUseScreenVariants(true, {
  mobile: "(min-width:0px) and (max-width:768px)",
  tabletPortrait: "(max-width:1024px)",
  tabletLandscape: "(max-width:1224px)",
  mobileXSmall: "(max-width:576px)",
});

export default MobileContext;
/* prettier-ignore-end */
