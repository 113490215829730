// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type UnionSvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function UnionSvgIcon(props: UnionSvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 12 12"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M.646 10.646a.5.5 0 00.707.708L2.83 9.877l.004.002.716-.716-.004-.002 5.68-5.68.003.003.707-.707-.003-.003 1.42-1.42a.5.5 0 10-.707-.708L9.17 2.123C8.307 1.483 7.245 1 6 1c-1.583 0-2.872.782-3.828 1.675S.55 4.61.202 5.227a1.58 1.58 0 000 1.547c.335.594.964 1.582 1.865 2.452l-1.42 1.42zM2.774 8.52l5.68-5.68C7.742 2.338 6.92 2 6 2c-1.237 0-2.294.61-3.147 1.406-.851.795-1.457 1.74-1.78 2.311a.58.58 0 000 .565c.312.554.89 1.458 1.7 2.237z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M3.703 10.424l.75-.75c.48.204.996.326 1.547.326 1.237 0 2.294-.61 3.146-1.406.852-.795 1.458-1.74 1.78-2.311a.58.58 0 000-.565c-.216-.384-.56-.935-1.02-1.497l.71-.71a11.47 11.47 0 011.181 1.716c.27.478.27 1.067 0 1.546-.347.617-1.012 1.66-1.968 2.552C8.873 10.218 7.584 11 5.999 11c-.852 0-1.62-.227-2.296-.576z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default UnionSvgIcon;
/* prettier-ignore-end */
